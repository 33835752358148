const AimsPrograms = [
  {
    id: 1,
    value: "MRI Technologist",
    label: "MRI Technology",
  },
  {
    id: 2,
    value: "Diagnostic Medical Sonography",
    label: "Diagnostic Medical Sonography",
  },
  {
    id: 3,
    value: "Cardiovascular Technologist",
    label: "Cardiovascular Technology",
  },
  {
    id: 4,
    value: "Diagnostic Cardiac Sonography",
    label: "Diagnostic Cardiac Sonography",
  },
  {
    id: 5,
    value: "Neurodiagnostic Technologist",
    label: "Neurodiagnostic Technology",
  },
  { id: 6, value: "Surgical Technologist", label: "Surgical Technology" },
  { id: 7, value: "Endoscopy Technician", label: "Endoscopy Technician" },
  {
    id: 8,
    value: "Sterile Processing Technician",
    label: "Sterile Processing Technician",
  },
  { id: 9, value: "Medical Assistant", label: "Medical Assistant" },
  { id: 10, value: "Pharmacy Technician", label: "Pharmacy Technician" },
  {
    id: 11,
    value: "Medical Billing and Coding",
    label: "Medical Billing and Coding",
  },
  {
    id: 12,
    value: "Cardiac Monitor Technician",
    label: "Cardiac Monitor Technician",
  },
  { id: 13, value: "Phlebotomy Technician", label: "Phlebotomy Technician" },
  {
    id: 14,
    value: "EKG/Phlebotomy Technician",
    label: "EKG/Phlebotomy Technician",
  },
  {
    id: 15,
    value: "Patient Care Technician",
    label: "Patient Care Technician",
  },
  {
    id: 16,
    value: "Anesthesia Technician",
    label: "Anesthesia Technician",
  },
];

const AdditionalAimsPrograms = [
  {
    id: 17,
    value: "AAS in MRI Technology",
    label: "AAS in MRI Technology",
  },
  {
    id: 18,
    value: "AAS in Cardiovascular Technology",
    label: "AAS in Cardiovascular Technology",
  },
  {
    id: 19,
    value: "AAS in Neurodiagnostic Technology",
    label: "AAS in Neurodiagnostic Technology",
  },
  {
    id: 20,
    value: "AAS in Surgical Technology",
    label: "AAS in Surgical Technology",
  },
  {
    id: 21,
    value: "AAS in Diagnostic Medical Sonography",
    label: "AAS in Diagnostic Medical Sonography",
  },
];

export { AdditionalAimsPrograms };

AimsPrograms.selectPrograms = (list) => {
  return list.map((item) =>
    AimsPrograms.find((ap) => ap.value === item.program)
  );
};

AimsPrograms.selectProgram = (slug) => {
  return AimsPrograms.find((ap) => ap.value === slug);
};

AimsPrograms.defaults = AimsPrograms.map((program, idx) => ({
  id: idx,
  program: program.value,
}));

export default AimsPrograms;
