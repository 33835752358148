import React from "react";
import StarsForm from "./Forms/StarsForm";
import { AuthTokenContext } from "../providers";
import AimsPrograms from "./Forms/AimsPrograms";

const StarsModal = ({ onClose }) => {
  const token = React.useContext(AuthTokenContext);

  return (
    <div className="stars-modal">
      <div className="modal-wrapper">
        <button type="button" className="btn-close" onClick={onClose}>
          &times;
        </button>

        <div className="overlay" />

        <div className="modal-content">
          <h3 className="title">Request Information</h3>

          <p>
            To learn more about AIMS Education and the academic programs we
            offer, please fill out the form below and click submit.
          </p>

          <StarsForm token={token} programs={AimsPrograms} />
        </div>
      </div>
    </div>
  );
};

export default StarsModal;
