import React from "react";
import StarsForm from "./Forms/SimplifiedStarsForm";
import AimsPrograms from "./Forms/AimsPrograms";

import { AuthTokenContext } from "../providers";

const SimplifiedStarsModal = ({ slug, onClose }) => {
  const token = React.useContext(AuthTokenContext);
  const program = AimsPrograms.selectProgram(slug);

  return (
    <div className="stars-modal">
      <div className="modal-wrapper">
        <button type="button" className="btn-close" onClick={onClose}>
          &times;
        </button>

        <div className="overlay" />

        <div className="modal-content">
          <h3 className="title">Request Information</h3>

          <p>
            To learn more about AIMS Education and the academic programs we
            offer, please fill out the form below and click submit.
          </p>

          <StarsForm
            token={token}
            programs={[program]}
            preselectedProgram={program.value}
          />
        </div>
      </div>
    </div>
  );
};

export default SimplifiedStarsModal;
